import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';

const SectionTitle = styled(Heading)`
  margin-top: 30px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;

export const Staking = () => {
  const { formatMessage } = useIntl();
  return (
    <PageTemplate title={formatMessage({ defaultMessage: 'Why use ETHPAR?' })}>
      <section>
        <SectionTitle level={3} className="mb5">
          <FormattedMessage defaultMessage="Faster, Fairer, Lower cost" />
        </SectionTitle>
        <ul>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="100x more throughput" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="10x lower transaction fees" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Pays more to Stakers (12% vs 4%)" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Not a layer 2, but a Layer 1 fork of realEthereum" />
            </Text>
          </li>
        </ul>
      </section>
    </PageTemplate>
  );
};
