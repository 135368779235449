import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';

const SectionTitle = styled(Heading)`
  margin-top: 30px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;

export const Roadmap = () => {
  const { formatMessage } = useIntl();
  return (
    <PageTemplate
      title={formatMessage({
        defaultMessage: 'ETHPAR roadmap for faster, fairer Ethereum',
      })}
    >
      <section>
        <SectionTitle level={3} className="mb5">
          <FormattedMessage defaultMessage="Parallel at every level" />
        </SectionTitle>
        <ol>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Parallel block creation (September 2024 announcement)" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Custom order flows with “healthy” MEV (Q4 2024)" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Parallel contracts and dapps (Q1 2025)" />
            </Text>
          </li>
          <li>
            <Text className="mt10">
              <FormattedMessage defaultMessage="EVM execution on FPGA dedicated hardware (Q3 2025)" />
            </Text>
          </li>
        </ol>
      </section>
    </PageTemplate>
  );
};
